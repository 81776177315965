@font-face {
  font-family: "Itim";
  src: local('Itim-Regular'), url("./fonts/Itim-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "AppleGothic";
  src: local('AppleGothic'), url("./fonts/AppleGothic.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica-Light";
  src: local('Helvetica-Light'), url("./fonts/Helvetica-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: local('Poppins-Light'), url("./fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local('Poppins-Bold'), url("./fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local('Poppins-SemiBold'), url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

.farewell-notes {
  color: #4169E1;
  font-family: "Itim";
  padding: 0;
  border: none;
  background: none;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 273px;
  height: 100%;
}

html, body {
  font-family: 'Sen', sans-serif;
  color: #646464;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins-Light', sans-serif;
  font-weight: 700;
  color: #646464;
}

.h1 {
  font-size: 5vw;
  max-width: 500px;
  text-align: center;
  padding: 10px;
  margin-top: 1.5em;
}

@media (min-width: 768px) {
  .h1 {
    font-size: 1.5vw;
  }
}

.h2 {
  font-size: 4vw;
  max-width: 500px;
  text-align: center;
  padding: 10px
}

@media (min-width: 768px) {
  .h2 {
    font-size: 1.2vw;
  }
}

.next-section-button {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue-section {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  background-color: #E5EEFD;
}

.white-section {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  background-color: white
}

.main {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(229,238,253,1)  0%, rgba(255,255,255,1) 100%);
}

.section-1 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, #e3f8f3  0%, rgba(255,255,255,1) 100%);
}

.section-2 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, #f0dff6  0%, rgba(255,255,255,1) 100%);
}

.section-3 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, #fbf8e5  0%, rgba(255,255,255,1) 100%);
}

.section-4 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, #f6e7db  0%, rgba(255,255,255,1) 100%);
}

.value-prop {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.store-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.store-link {
  margin: 10px 0;
}

.store-link img {
  width: 200px;
}

.icon {
  width: 160px;
}

.value-prop-and-phone-spacer {
  max-width: 80px;
  width: 80px;
}

.security-spacer {
  max-width: 100px;
  width: 100px;
}

.phone {
  max-width: 300px;
  margin-top:80px;
  margin-right:30px;
  margin-left:30px;
}

.security {
  max-width: 250px;
  margin-top:80px;
  margin-right:30px;
  margin-left:30px;
}

footer {
  text-align: center;
}

.footer-link {
  padding: 3px 5px;
  border-radius: 5px;
  text-decoration: none;
  color: #2478fb;
}