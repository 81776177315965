.container {
  width:100%;
  display: flex;
  justify-content: center;
}

.checkbox {
  height: 0px;
  width: 25px;
  bottom: 0.2em;
}

.activity-title {
  margin-left: 1.0em;
  margin-top: 1.4em;
  font-size: 1.5em;
  font-weight: bold;
}

.loading {
  margin-left: 7em;
  margin-top: 1.4em;
  font-size: 1.4em;
} 

.activity-error {
  margin-left: 1.2em;
  margin-right: 1.2em;
  margin-top: 1.4em;
  font-size: 1.4em;
  text-align: center;
} 

.button-container {
  margin-left: 0em;
  flex-direction: column;
  display: flex;
}

.content {
  margin: 2em;
}