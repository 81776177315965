/* General Styles */
body {
    font-family: Arial, sans-serif;
  }
  
  .pricing-wrapper {
    text-align: center;
    padding: 2rem 1rem;
    background-color: #E5EEFD;
  }
  
  .pricing-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .pricing-subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  /* Card Container */
  .pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }
  
  /* Card Styles */
  .pricing-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 1.5rem;
    width: 280px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .popular {
    border-color: #4a90e2;
  }
  
  .badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #4a90e2;
    color: white;
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
    border-radius: 5px;
  }
  
  .plan-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .plan-price {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .plan-duration {
    font-size: 1rem;
    color: #888;
  }
  
  .features-list {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
    text-align: left; /* Align text to the left */
  }
  
  .feature {
    font-size: 0.9rem;
    color: #555;
    margin: 0.3rem 0;
    padding-left: 1.2rem; /* Add consistent indentation */
    text-indent: -1.2rem; /* Pull text back to the left */
  }
  
  /* Button */
  .plan-button {
    background: #4a90e2;
    color: white;
    border: none;
    padding: 0.7rem 1.2rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .plan-button:hover {
    background: #357abd;
  }

  .feature-check {
    color: #04b207;
  }
  