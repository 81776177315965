.email-container li {
  list-style-type: none;
  padding-inline-end: 20px;
}

.email-container {
  width:100%;
  display: flex;
  justify-content: center;
}

.checkbox {
  height: 0px;
  width: 25px;
  bottom: 0.2em;
}

.unsubscribe-title {
  margin-left: 1.0em;
  margin-top: 1.4em;
  font-size: 1.5em;
  font-weight: bold;
}

.loading {
  margin-left: 7em;
  margin-top: 1.4em;
  font-size: 1.4em;
} 

.unsubscribe-description {
  margin-left: 1.2em;
  margin-top: 1.4em;
  font-size: 1.4em;
} 

.unsubscribe-error {
  margin-left: 1.2em;
  margin-right: 1.2em;
  margin-top: 1.4em;
  font-size: 1.4em;
} 

.category {
  font-size: 1.3em;
  font-weight: bold;
  color: #4169E1
}

.category-description {
  font-size: 1.2em;
  font-weight: bold;
  font-style: italic;
}

.subcategory {
  font-size: 1.2em;
  font-weight: bold;
  color: #4A90E2
}

.subcategory-description {
  font-size: 1.1em;
  font-style: italic;
}

.update-preferences-button {
  margin-left: 4em;
}